import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'index',
        // component: () => import('../views/index.vue')
        component: () => import('../views/sensorDataView.vue')
    },
  {
    path: '/sensor_data_view',
    name: 'sensor_data_view',
    // component: () => import('../views/sensorDataView.vue')
    component: () => import('../views/index.vue')
  }

]
const router = new VueRouter({
    routes
})

export default router